$white: #fff;
$body-bg: #f8f8f8;
$gray-100: #babfc7; 
$gray-200: #ededed;
$gray-300: #dae1e7; 
$gray-400: #636363;
$gray-500: #adb5bd;
$gray-600: #b8c2cc;
$gray-700: #4e5154;
$gray-800: #1e1e1e; 
$gray-900: #2a2e30;
$black: #22292f;
$blue: #00cfe8; 
$red: #ea5455; 
$orange: #ff9f43; 
$green: #28c76f; 
$cyan: #AC368A;
$check: #AC368A;
$primary: $cyan;
$info: $blue;
$warning: $orange;
